<template>
    <div id="header" style="z-index:1000;">

        <transition name="curtain">
            <div class="curtain" v-if="TenantDropDownVisible||AvatarDropDownVisible"></div>
        </transition>

        <!-- <img id="logo" src="../../public/Logo32x32.svg" /> -->
        <img id="logo" v-bind:src="appLogo" />

        <div id="avatar" v-if="UserData!=null" @click="toggleAvatarDropDown()" @focusout="hideAvatarDropDown()" class="no-outline" tabindex="1">
            <Avatar :UserName="UserData.userName" :Initials="UserData.initials" style="cursor:pointer;" />
            <transition name="headermodal">
                <div class="avatarmodal" v-show="AvatarDropDownVisible">
                    <Avatar id="bigavatar"
                            :UserName="UserData.userName"
                            :Initials="UserData.initials"
                            style="cursor:pointer;"
                            Size="large"
                            @click="SetOpenAvatarImageRightPanel">
                    </Avatar>

                    <span class="username">{{UserData.name}}</span>
                    <hr />
                    <div class="headermodalsection">
                        <img src="../../public/icons/header/cloud2.svg" />
                        <div><span style="font-weight:bold">{{Localizer.Localize("Header_Tenant")}}</span> <br /> {{Tenant.name}}</div>
                    </div>
                    <hr />
                    <div class="headermodalsection">
                        <img src="../../public/icons/header/role.svg" />
                        <div>
                            <span style="font-weight:bold">{{Localizer.Localize("Header_Roles")}}</span>
                            <br />
                            <div v-for="role in UserData.roles">{{role}}</div>
                            <span v-if="UserData.roles == null || UserData.roles.length==0">No roles.</span>
                        </div>
                    </div>
                    <hr />
                    <div class="headermodalsection">
                        <img src="../../public/icons/header/version.svg" />
                        <div>
                            <span style="font-weight:bold">{{Localizer.Localize("Header_Version")}}</span>
                            <br />
                            <div>{{Localizer.Localize("Header_Portal")}}: 2405.3</div>
                            <div v-if="UserData.releaseInfo!=null">{{Localizer.Localize("Header_Platform")}}: {{UserData.releaseInfo.platform}}</div>
                            <div v-if="UserData.releaseInfo!=null && UserData.releaseInfo.consolidationModule!=null">{{Localizer.Localize("Header_Consolidation")}}: {{UserData.releaseInfo.consolidationModule}}</div>
                            <div v-if="UserData.releaseInfo!=null && UserData.releaseInfo.accountRecModule!=null">{{Localizer.Localize("Header_Account_Rec")}}: {{UserData.releaseInfo.accountRecModule}}</div>
                            <div v-if="UserData.releaseInfo!=null && UserData.releaseInfo.matchingModule!=null">{{Localizer.Localize("Header_Transaction_Matching")}}: {{UserData.releaseInfo.matchingModule}}</div>
                        </div>
                    </div>
                    <hr />

                    <!-- <div class="headerbtn" @click="changeTheme"><img src="../../public/icons/header/color.svg" style="visibility:hidden" />{{Localizer.Localize("Header_Change_Theme")}}</div> -->
                    <div class="headerbtn" @click="goToNotifications"><img src="../../public/icons/header/color.svg" style="visibility:hidden" />{{Localizer.Localize("Header_System_Log")}}</div>
                    <div class="headerbtn" @click="openZenDesk" v-if="!hideFluenceHelp"><img src="../../public/icons/header/color.svg" style="visibility:hidden" />{{Localizer.Localize("Header_Support")}}</div>
                    <hr />
                    <div class="headerbtn" @click="Signout_Click"><img src="../../public/icons/header/color.svg" style="visibility:hidden" />{{Localizer.Localize("Header_Sign_Out")}}</div>
                </div>
            </transition>
        </div>
        <div id="avatar" v-else class="no-outline" tabindex="1">
            <Avatar Initials="..." />
        </div>

        <div id="chat" @click="OpenChatPopup" v-if="!hideFluenceHelp">
            <div class="headerbtnmain" style="width: 45px; height: 100%; margin: auto">
                <img src="../../public/icons/chat.svg" />
            </div>
        </div>

        <div id="languages" style="z-index:9999;">
            <div class="headerbtnmain" style="width: 45px; height: 100%; margin:auto" :title="this.getLanguageTitleText()">
                <input type="file" id="loadLanguageFile" style="display: none" @change="LoadCustomLanguage" accept="text/json" />
                <f-dropdown :trigger="click" class="no-outline">
                    <f-icon-button icon="translation" color="white" />
                    <template v-slot:overlay>
                        <f-menu :items="localeLangs()" @click="setOverrideLang" v-model:value="selLang">
                            <template v-slot:item="{item}">
                                <div :title="Localizer.Localize(item.name.length==2 ? 'lng_' + item.name.toUpperCase():'')">
                                    {{item.caption}} <span v-if="item.name.length==2">({{item.name.toUpperCase()}})</span>
                                </div>
                            </template>
                        </f-menu>
                    </template>
                </f-dropdown>
            </div>
        </div>

        <div id="whatsnew" @click="OpenReleaseNotes()" v-if="!hideWhatsNew">
            <div class="headerbtnmain" style="width: 45px; height: 100%; margin: auto">
                <img src="../../public/icons/dialog/whatsnew.svg" />
            </div>
        </div>

        <div id="tenant" v-if="Tenant!=null" @click="toggleTenantDropDown()" class="no-outline" tabindex="1" v-bind:style="{backgroundColor:Tenant.bgColor==null?'#3a3b45':Tenant.bgColor}">
            <div class="headerbtnmain" style="line-height:46px; min-width:150px;"><img src="../../public/icons/header/cloud.svg" /> &nbsp; {{FormatTenantName(Tenant.name, Tenant.caption)}}</div>
            <transition name="headermodal">
                <div class="tenantmodal" v-show="TenantDropDownVisible">
                    <div class="headerbtn" @click="switchTenant"><img src="../../public/icons/header/cloud2.svg" /> &nbsp;{{Localizer.Localize("Header_Switch_Tenant")}}</div>
                    <div class="headerbtn" @click.stop="chooseBackground">
                        <img src="../../public/icons/header/color.svg" /> &nbsp;{{Localizer.Localize("Header_Change_Background")}}
                    </div>
                    <div v-if="ChooseBackgroundSelected">
                        <div class="swatch" style="background-color:#081C53" @click="chooseBackgroundContinued('#081C53')" />
                        <div class="swatch" style="background-color:#E54C5F" @click="chooseBackgroundContinued('#E54C5F')" />
                        <div class="swatch" style="background-color:#C882D9" @click="chooseBackgroundContinued('#C882D9')" />
                        <div class="swatch" style="background-color:#B5BDD5" @click="chooseBackgroundContinued('#B5BDD5')" />
                        <div class="swatch" style="background-color:#5AC4AD" @click="chooseBackgroundContinued('#5AC4AD')" />
                        <div class="swatch" style="background-color:#FFA437" @click="chooseBackgroundContinued('#FFA437')" />
                    </div>
                </div>
            </transition>
        </div>

        <input id="avatarImageSelector" ref="avatarImageSelector" type="file" style="display: none;" @change="UploadUserImage" accept="image/png" />



        <WhatsNewDialog v-if="showReleaseNotes==true" @close="showReleaseNotes=false" :listOfMessages="listOfNotes" :message="releaseNotes" :ishtml="true" :size="'lg'">
        </WhatsNewDialog>


        <RightPanel v-if="OpenAvatarImageRightPanel" @close="OpenAvatarImageRightPanel=false" style="font-size:14px; color:var(--foreground-primary);">
            <template v-slot:header>
                <span class="title">Choose Avatar Image</span>
            </template>
            <template v-slot:body>
                <div class="bodyContents">
                    <label>Generate:</label>
                    <textarea rows="3" type="text" v-model="AiPrompt" id="AvatarAiPrompt" />
                </div>
                <div v-if="IsBusy">
                    <Spinner />
                </div>
                <div v-if="!IsBusy" style="margin:0 auto;">
                    <img v-if="AiUrl!=''" :src="AiUrl" style="width:128px; height:128px; display:block;" />
                </div>
                <a v-if="!IsBusy && AiUrl!=''" @click="UploadAiImage()" href="#">Use this image</a>
            </template>
            <template v-slot:footer>
                <div class="panelButtons">
                    <div class="btnViewer" @click="OpenAvatarImageRightPanel=false">CANCEL</div>
                    <div class="btnViewer btnViewerDefault" v-if="AiPrompt!='' && !IsBusy" @click="GenerateAiImage()">{{Localizer.Localize("GENERATE")}}</div>
                    <div class="btnViewer btnViewerDefault" @click="OpenFilePicker()">{{Localizer.Localize("Pick_File")}}</div>
                </div>
                <br />
            </template>
        </RightPanel>

    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
    import { Localizer } from "../api/Localizer";
    import * as api from "../api/ApiClient";
    import * as base from "../api/ApiClientBase";
    import RouteNames from '../routing/RouteNames';
    import { WindowsTabData, ChatWindowsTabData } from '../api/WindowsTabData';

    import Avatar from './helpers/Avatar.vue';
    import RightPanel from "./helpers/RightPanel.vue";
    import Spinner from "./helpers/Spinner.vue";
    import WhatsNewDialog from "../components/helpers/WhatsNewDialog.vue";
    import HtmlEditor from "../components/pages/workflow/Editors/Controls/HtmlEditor.vue";
    import { AuthClient } from "../api/ApiClientBase";
    import languages from "../../public/locales/languages.json"


    @Component({ components: { Avatar, RightPanel, Spinner, Watch, WhatsNewDialog, HtmlEditor, AuthClient } })
    export default class Header extends Vue {

        private Localizer = new Localizer(this.$store);
        private RouteNames = RouteNames;

        get Tenant(): api.Tenant { return this.$store.state.security.Tenant; }
        get UserName(): api.Tenant { return this.$store.state.security.UserName; }
        get UserData(): api.ExtendedUserData { return this.$store.state.security.UserData; }
        get NewVersionDownloading(): boolean { return this.$store.state.NewVersionDownloading; }
        get NewVersionDownloaded(): boolean { return this.$store.state.NewVersionDownloaded; }
        get WhatsNewArticles(): api.WhatsNewArticle[] { return this.$store.state.whatsnew.Articles; }

        get FeatureFlagLocalesDebug(): boolean {
            var ud = this.$store.state.security.UserData as api.ExtendedUserData;
            if (ud != null && ud.featureFlags != null) {
                for (var i = 0; i < ud.featureFlags!.length; i++) {
                    if (ud.featureFlags[i] == "LocalesDebug")
                        return true;
                }
            }
            return false;
        }

        cachedLocaleLangs: any | null = null;

        localeLangs() {
            if (this.cachedLocaleLangs == null) {
                if (typeof languages[0] === "string") {
                    let langs = languages.map((x: any) => {
                        return {
                            name: x as string,
                            caption: (x as string).toUpperCase()
                        }
                    });

                    this.cachedLocaleLangs = langs
                }
                var temp = languages.sort((a: any, b: any) => {
                    if (a.name.toUpperCase() == "EN")
                        return -1;
                    if (b.name.toUpperCase() == "EN")
                        return 1;
                    return a.name.localeCompare(b.caption);
                }).map(x => x);
                this.cachedLocaleLangs = temp;
            }
            var result = [...this.cachedLocaleLangs]
            if (this.FeatureFlagLocalesDebug) {
                result.push({ name: 'Debug', caption: 'Debug' });
                result.push({ name: 'Import File', caption: 'Import File' });
                result.push({ name: 'Export File', caption: 'Export File' });
            }

            return result;
        }


        getLanguageTitleText(): string {
            let cookieOverride = localStorage.getItem("UserLocaleOverride") ? " (" + localStorage.getItem("UserLocaleOverride")?.toUpperCase()! + ")" : '';
            return this.Localizer.Localize('Header_Select_Language') + cookieOverride;
        }

        ChooseBackgroundSelected: boolean = false;

        private selLang: string | undefined = localStorage.getItem("UserLocaleOverride") ? localStorage.getItem("UserLocaleOverride")! : 'en';

        TenantDropDownVisible: boolean = false;
        toggleTenantDropDown() {
            this.TenantDropDownVisible = !this.TenantDropDownVisible;
            if (!this.TenantDropDownVisible)
                this.ChooseBackgroundSelected = false;
        }

        TranslationtDropDownVisible: boolean = false;
        toggleTranslationtDropDown(visible: boolean) {
            this.TranslationtDropDownVisible = visible;
        }

        AvatarDropDownVisible: boolean = false;
        toggleAvatarDropDown() {
            this.AvatarDropDownVisible = !this.AvatarDropDownVisible;
            if (this.AvatarDropDownVisible)
                this.OpenAvatarImageRightPanel = false;
        }

        hideAvatarDropDown() {
            this.AvatarDropDownVisible = false;
        }

        get hideWhatsNew() {
            var forceupdate = this.UserData?.tenant;
            var hide = this.UserData == null || AuthClient.configuration.$config?.hideWhatsNew;
            return hide ?? false;
        }

        get hideFluenceHelp() {
            var forceupdate = this.UserData?.tenant;
            var hide = this.UserData == null || AuthClient.configuration.$config?.hideFluenceHelp;
            return hide ?? false;
        }



        showReleaseNotes: boolean = false;
        async OpenReleaseNotes() {
            if (this.$store.state.whatsnew.Articles == null) {
                await this.$store.dispatch("whatsnew/GetArticles", true);
            }

            var notes = this.$store.state.whatsnew.Articles;
            if (notes == null || notes.length == 0) {
                this.showReleaseNotes = false;
            }
            else if (notes.length == 1) {
                if (notes[0].htmlText == null || notes[0].htmlText == "") {
                    this.showReleaseNotes = false;
                }
                else {
                    this.showReleaseNotes = true;
                }
            }
            else {
                this.showReleaseNotes = true;
            }
        }

        @Watch("UserData") async onUserDataLoad(val: api.UserData | undefined) {
            if (this.UserData != null) {
                var newVersion = this.UserData?.releaseInfo?.platform;
                var oldVersion = localStorage.getItem('whatsnewversion');

                if (!this.hideWhatsNew && (oldVersion == null || newVersion == null || oldVersion < newVersion)) {
                    await this.OpenReleaseNotes();
                    if (newVersion != null)
                        localStorage.setItem('whatsnewversion', newVersion);
                }
            }
        }

        mounted() {
        }

        setOverrideLang(item: { name: string, caption: string }) {

            if (item.name == "Import File") {
                this.toggleTranslationtDropDown(false);

                const loadLanguageFile: HTMLElement | null = document.getElementById("loadLanguageFile");
                loadLanguageFile!.click();

                return;
            }
            else if (item.name == "Export File") {
                this.toggleTranslationtDropDown(false);

                var locales = this.$store.state.Locale;
                const data = JSON.stringify(locales);
                const blob = new Blob([data], { type: 'text/plain' })
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = localStorage.getItem("UserLocaleOverride") + ".json";
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                e.initEvent('click', true, false);
                a.dispatchEvent(e);

                return;
            }
            else {
                Localizer.DebugMode = (item.name == "Debug");

                this.$store.dispatch("SetLocalizationLanguage", item.name);
                if (item.name != "Debug")
                    localStorage.setItem("UserLocaleOverride", item.name);
            }
            let c_event = new CustomEvent("langOverrideRefresh");
            dispatchEvent(c_event);

            this.toggleTranslationtDropDown(false);
        }

        LoadCustomLanguage(event: any) {
            const files = event.target.files
            let filename = files[0].name
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.$store.dispatch("SetCustomLocalizationLanguage", fileReader.result);
                let c_event = new CustomEvent("langOverrideRefresh");
                dispatchEvent(c_event);
            })
            fileReader.readAsText(files[0]);
        }

        get appLogo() {
            return this.$store.state.Theme['--main-logo'];
        }

        switchTenant() {
            this.$router.push({ name: RouteNames.WorkflowContributorRoute });
            this.$store.dispatch("CacheCleanup");
            this.$store.dispatch("contributor/ReLoadMyActivities");
        }

        chooseBackground() {
            this.ChooseBackgroundSelected = true;
        }

        async chooseBackgroundContinued(color: string) {
            var auth_client = new base.AuthClient();
            var client = new api.SecurityClient(auth_client);
            await client.setTenantBgColor(color);
            this.Tenant.bgColor = color;
        }

        goToNotifications() {
            this.$router.push({ name: RouteNames.NotificationRoute });
        }

        Signout_Click() {
            this.$store.dispatch("security/LogOut");
        }

        RefreshBrowser() {
            window.location.reload();
        }

        openZenDesk() {
            var url = "http://support.fluencetech.com/";
            window.open(url, '_blank', 'noreferrer');
        }

        get Tabs(): WindowsTabData[] {
            return this.$store.state.windowstabs.Tabs;
        }

        OpenChatPopup() {
            var tab = new ChatWindowsTabData("Chat");
            this.Tabs!.push(tab);
        }



        private OpenAvatarImageRightPanel: boolean = false;
        private IsBusy: boolean = false;
        private AiPrompt: string = "";
        private AiUrl: string = "";

        SetOpenAvatarImageRightPanel() {
            this.OpenAvatarImageRightPanel = true;
            this.AiUrl = '';

            setTimeout(() => {
                var x = document.getElementById("AvatarAiPrompt");
                x!.focus();
            }, 150)
        }

        OpenFilePicker() {
            var avatarImageSelector: HTMLInputElement | null = this.$refs.avatarImageSelector as HTMLInputElement;
            if (avatarImageSelector != null)
                avatarImageSelector.click();
            else
                console.log("avatarImageSelector is null");
        }

        async GenerateAiImage() {
            this.IsBusy = true;

            try {
                const authClient = new base.AuthClient();
                await authClient.ensureToken();
                const client = new api.SecurityClient(authClient);
                this.AiUrl = "data:image/png;base64, " + await client.generateUserImage(this.AiPrompt);
            }
            catch (e) {
                await this.$store.dispatch("ShowToastError", e);
            }

            this.IsBusy = false;
        }

        async UploadAiImage() {

            try {
                const authClient = new base.AuthClient();
                await authClient.ensureToken();
                const client = new api.SecurityClient(authClient);

                const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
                    const byteCharacters = atob(b64Data);
                    const byteArrays = [];

                    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                        const slice = byteCharacters.slice(offset, offset + sliceSize);

                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }

                        const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }

                    const blob = new Blob(byteArrays, { type: contentType });
                    return blob;
                }
                await this.$store.dispatch("ShowToast", new api.ToastNotification({
                    message: this.Localizer.Localize("Header_Uploading_Avatar_Image"),
                    type: api.ToastType.Info
                }));

                const blob = b64toBlob(this.AiUrl.substring("data:image/png;base64, ".length), "image/png");
                await client.setUserImage({ fileName: 'ai.png', data: blob as Blob });

                await this.$store.dispatch("ShowToast", new api.ToastNotification({
                    message: this.Localizer.Localize("Header_Avatar_image_changed"),
                    type: api.ToastType.Info
                }));

                localStorage.removeItem("Avatar_" + this.UserName);
                //await this.onUserNameChange();

                this.$store.commit("security/IncreaseAvatarVersion");
            }
            catch (err) {
                this.$store.dispatch("ShowToastError", err);
            }
        }

        async UploadUserImage() {

            var fileToUpload: File | null = null;
            var avatarImage: HTMLInputElement | null = this.$refs.avatarImageSelector as HTMLInputElement;

            if (avatarImage && avatarImage.files && avatarImage.files.length > 0) {
                var fileToUpload = avatarImage.files.item(0);

                var filePath = fileToUpload!.name;

                if (!filePath.endsWith(".png")) {
                    await this.$store.dispatch("ShowToastError", this.Localizer.Localize("Header_Only_png_images"));
                }
                else {
                    try {
                        const authClient = new base.AuthClient();
                        await authClient.ensureToken();
                        const client = new api.SecurityClient(authClient);
                        await client.setUserImage({ fileName: filePath, data: fileToUpload as Blob });

                        await this.$store.dispatch("ShowToast", new api.ToastNotification({
                            message: this.Localizer.Localize("Header_Avatar_image_changed"),
                            type: api.ToastType.Info
                        }));

                        localStorage.removeItem("Avatar_" + this.UserName);
                        //await this.onUserNameChange();

                        this.$store.commit("security/IncreaseAvatarVersion");
                    }
                    catch (err) {
                        this.$store.dispatch("ShowToastError", err);
                    }
                }
            }
        }

        private FormatTenantName(name: string, caption: string) {
            if (caption != null)
                return this.Localizer.ParseLocalizedString(caption);
            name = name.replaceAll("_", " ");
            if (name.length < 10 || name.indexOf(' ') > 0)
                return name;
            else {
                return name.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #header {
        background-color: var(--header-background);
        color: var(--accent-foreground);
        height: 46px;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2000;
    }

    #logo {
        margin-top: 7px;
        margin-left: 17px;
        width: 32px;
        height: 32px;
    }

    #avatar {
        margin-top: 6px;
        margin-right: 10px;
        float: right;
    }

    #bigavatar {
        margin-top: 14px;
        margin-left: 12px;
        display: inline-block;
    }

    #chat {
        height: 46px;
        float: right;
        text-align: center;
        line-height: 46px;
        margin-right: 8px;
    }

    #tenant {
        height: 46px;
        float: right;
        text-align: center;
        line-height: 46px;
        margin-right: 8px;
    }

    #languages {
        cursor: pointer;
        height: 46px;
        float: right;
        text-align: center;
        line-height: 46px;
    }

    #whatsnew {
        cursor: pointer;
        height: 46px;
        float: right;
        text-align: center;
        line-height: 46px;
    }

    #whatsnewimg {
        height: 24px;
        width: 24px;
        vertical-align: middle;
        float: right;
        text-align: center;
        line-height: 40px;
    }

    .curtain {
        position: fixed;
        z-index: 3000;
        top: 86px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: var(--background-curtain);
    }

    .curtain-enter-active, .curtain-leave-active {
        transition: opacity .3s;
    }

    .curtain-enter-from, .curtain-leave-to {
        opacity: 0;
    }


    .no-outline {
        outline: none;
    }

    .tenantmodal {
        color: #555555;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
        border-radius: 4px;
        position: fixed;
        margin-top: 6px;
        z-index: 4000;
        text-align: left;
    }

    .avatarmodal {
        color: #555555;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
        border-radius: 4px;
        position: fixed;
        margin-left: -205px;
        margin-top: 6px;
        width: 250px;
        z-index: 4000;
        text-align: left;
    }

    .headermodal-enter-active, .headermodal-leave-active {
        transition: all .3s ease;
    }

    .headermodal-enter-from, .headermodal-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }

    .username {
        margin-left: 19px;
        font-size: 14px;
        font-weight: bold;
    }

    .headermodalsection {
        margin-left: 10px;
        line-height: 20px;
    }

        .headermodalsection > img {
            vertical-align: top !important;
        }

        .headermodalsection > div {
            display: inline-block;
            padding-left: 10px;
        }

    .headerbtn {
        cursor: pointer;
        padding-right: 10px;
        padding-left: 10px;
        line-height: 40px;
    }

        .headerbtn:hover {
            background-color: var(--header-btn-hover);
        }

    .headerbtnmain {
        cursor: pointer;
        padding-right: 10px;
        padding-left: 10px;
        line-height: 40px;
    }
        .headerbtnmain:hover {
            background: rgba(255, 255, 255, 0.16);
        }

    hr {
        margin-top: 11px;
        margin-bottom: 11px;
    }

    .swatch {
        width: 42px;
        height: 42px;
        border-radius: 11px;
        cursor: pointer;
        margin: 5px 5px 5px 5px;
        display: inline-block;
    }

    .notification {
        position: absolute;
        float: left;
        top: 0px;
        left: 0px;
        right: 0px;
        padding: 3px 10px;
        background-color: lightgoldenrodyellow;
        color: gray;
        border: 1px solid gray;
    }

    .closeNotification {
        float: right;
        margin: 3px;
        width: 18px;
        height: 18px;
    }
</style>

